import { Component } from '@angular/core';
import { Observable } from 'rxjs';


import { OffersService } from '../../services';
import { Offer, Product } from '../../models';
import { MatDialog, } from '@angular/material/dialog';
import { ProductGroup } from 'src/app/pages/products/models';


@Component({
  selector: 'app-offer-page',
  templateUrl: './offer-page.component.html',
  styleUrls: ['./offer-page.component.scss']
})
export class OfferPageComponent {

  public offerTableData$: Observable<Offer[]>
  public offers: ProductGroup[] = [];

  constructor(private service: OffersService, public dialog: MatDialog) {
    this.offerTableData$ = service.loadOffersFromHttp();
  }



}
