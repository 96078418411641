import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { OfferPageComponent } from './containers';


const routes: Routes = [

  {
    path: '',
    component: OfferPageComponent
  },

];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})

export class OffersRoutingModule {
}
