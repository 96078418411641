import { Injectable } from '@angular/core';
import { Observable, of, throwError as ObservableThrowError } from 'rxjs';

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Offer, Product, ProductGroup } from '../models';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class OffersService {


  constructor(private http: HttpClient) { }




  public loadOffersFromHttp(): Observable<Offer[]> {

    const token = localStorage.getItem('token');

    return this.http.get<Offer[]>(environment.apiUrl + "/offers/by-admin", { headers: { 'x-access-token': token } }).pipe(catchError(this.httpErrorHandler));


  }

  public loadActiveOffersFromHttp(): Observable<Offer[]> {

    const token = localStorage.getItem('token');

    return this.http.get<Offer[]>(environment.apiUrl + "/offers/all-active", { headers: { 'x-access-token': token } }).pipe(catchError(this.httpErrorHandler));


  }

  public updateOfferHttp(updatedVal: Offer): Observable<any> {

    const token = localStorage.getItem('token');

    return this.http.put<any>(environment.apiUrl + "/offers/" + updatedVal.id, updatedVal, { headers: { 'x-access-token': token } }).pipe(catchError(this.httpErrorHandler));


  }



  public deleteOfferHttp(updatedVal: Offer): Observable<any> {

    const token = localStorage.getItem('token');

    return this.http.delete<any>(environment.apiUrl + "/offers/" + updatedVal.id, { headers: { 'x-access-token': token } }).pipe(catchError(this.httpErrorHandler));


  }



  public addOfferHttp(updatedVal: Offer): Observable<any> {

    const token = localStorage.getItem('token');

    return this.http.post<any>(environment.apiUrl + "/offers", updatedVal, { headers: { 'x-access-token': token } }).pipe(catchError(this.httpErrorHandler));


  }




  httpErrorHandler(error: HttpErrorResponse) {
    return ObservableThrowError(error.message || "Server Error");
  }

}
