import { Component } from '@angular/core';
import { routes } from '../../consts/routes';

import {environment} from '../../../environments/environment';
 

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  public routes: typeof routes = routes;
  public isOpenUiElements = false;
  public buildTag:String="";

  constructor(){
    this.buildTag=environment.buildTag;

  }

  public openUiElements() {
    this.isOpenUiElements = !this.isOpenUiElements;
  }
}
