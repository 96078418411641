import { Component, Input, OnInit } from '@angular/core';

import { CustomerTrend, OfferTrend, OrderTrend, ProjectStatData, ProjectTimeData, SalesChartData, SalesmanTrend } from '../../models';
import { colors } from '../../../../consts';
import { DashboardService } from '../../services';

type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  grid: ApexGrid;
};

enum ProjectsType {
  lightBlue = 'lightBlue',
  SingApp = 'SingApp',
  RNS = 'RNS'
}

@Component({
  selector: 'app-sales-stat-chart',
  templateUrl: './sales-stat-chart.component.html',
  styleUrls: ['./sales-stat-chart.component.scss']
})
export class SalesStatChartComponent implements OnInit {
  @Input() projectsStatsData: ProjectStatData;
  public selectedStatsLightBlueData: ProjectTimeData;
  public selectedStatsSingAppData: ProjectTimeData;
  public selectedStatsRNSData: ProjectTimeData;
  public chartOptions: Partial<ChartOptions>;
  public projectsType: typeof ProjectsType = ProjectsType;
  public colors: typeof colors = colors;



  public customerChartOptions: Partial<ChartOptions>;
  public salesmanChartOptions: Partial<ChartOptions>;
  public orderChartOptions: any;
  public offerChartOptions: any;
  public recentActiveDays = 7;
  public totalCustomer: number;
  public totalActiveCustomer: number;
  public totalSalesman: number;
  public totalActiveSalesman: number;
  public orderInProgress: number = 0;
  public orderProcessed: number = 0;
  public orderCancelled: number = 0;
  public totalOrder: number = 1;



  constructor(private service: DashboardService,) {


    this.service.loadSalesCustomerChartData(this.recentActiveDays).subscribe((data: CustomerTrend) => {

      this.totalCustomer = data.result1[0].total_customer;
      this.totalActiveCustomer = data.result2.length;



      var newLables: string[] = [];
      var newValues: number[] = [];

      for (const saleDate of data.result2) {

        newLables.push(saleDate.Customername + " - " + saleDate.order_count + " Orders");
        newValues.push(saleDate.total_amount);


      }



      this.customerChartOptions = {
        chart: {
          type: 'bar',
          height: 100,
          width: 130,
          toolbar: {
            show: false
          }
        },
        series: [
          {
            name: 'Sales',
            type: 'line',
            data: newValues
          }
        ],
        legend: {
          show: false
        },
        grid: {
          show: false
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '70%',
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: newLables,
          labels: {
            show: false
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          show: false
        },
        tooltip: {
          y: {
            formatter(val) {
              return "₹ " + val;
            }
          }
        }
      };



    });


    this.service.loadSalesSalesmanChartData(this.recentActiveDays).subscribe((data: SalesmanTrend) => {

      this.totalSalesman = data.result1[0].total_salesman;
      this.totalActiveSalesman = data.result2.length;



      var newLables: string[] = [];
      var newValues: number[] = [];

      for (const saleDate of data.result2) {
        newLables.push(saleDate.Salesmanname + " - " + saleDate.order_count + " Orders");
        newValues.push(saleDate.total_amount);

      }



      this.salesmanChartOptions = {
        chart: {
          type: 'bar',
          height: 100,
          width: 130,
          toolbar: {
            show: false
          }
        },
        series: [
          {
            name: 'Sales',
            type: 'line',
            data: newValues
          }
        ],
        legend: {
          show: false
        },
        grid: {
          show: false
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '70%',
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: newLables,
          labels: {
            show: false
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          show: false
        },
        tooltip: {
          y: {
            formatter(val) {
              return "₹ " + val;
            }
          }
        }
      };


    });


    this.service.loadSalesOrderChartData(this.recentActiveDays).subscribe((data: OrderTrend[]) => {


      for (const orderStatus of data) {
        if (orderStatus.Status == 0) {
          this.orderInProgress = orderStatus.order_count;
          this.totalOrder += orderStatus.order_count;
        } else {
          if (orderStatus.Status == 1 && orderStatus.TransactionValidity) {
            this.orderCancelled = orderStatus.order_count;
            this.totalOrder += orderStatus.order_count;
          } else {
            this.orderProcessed = orderStatus.order_count;
            this.totalOrder += orderStatus.order_count;
          }
        }
      }


      this.orderChartOptions = {
        color: [colors.YELLOW, colors.GREEN, colors.PINK,],
        tooltip: {
          trigger: 'item',
          formatter: function (params) {

            return `Orders <br />
                    ${params.name}: ${params.data.value} (${params.percent}%)`;
          }
        },

        legend: {
          top: 'center',
          right: 'right',
          data: ['In-Progress', 'Processed', 'Cancelled'],
          textStyle: {
            color: '#6E6E6E'
          }
        },
        series: [{
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['24%', '50%'],
          label: {
            show: false
          },

          labelLine: {
            normal: {
              show: false
            }
          },
          hoverAnimation: false,
          avoidLabelOverlap: false,
          data: [
            {
              name: 'In-Progress',
              value: this.orderInProgress
            },
            {
              name: 'Processed',
              value: this.orderProcessed
            },
            {
              name: 'Cancelled',
              value: this.orderCancelled
            },
          ]
        }]
      };
    });



    this.service.loadSalesOfferChartData(this.recentActiveDays).subscribe((data: OfferTrend[]) => {



      var newLables: string[] = [];
      let map = new Map();

      for (const offerStatus of data) {


        if (offerStatus.coupon_code == null || offerStatus.coupon_code == "") {
          offerStatus.coupon_code = "NO-COUPON";
        }
        var tmpVal = offerStatus.order_count;
        if (map.has(offerStatus.coupon_code)) {
          tmpVal = tmpVal + map.get(offerStatus.coupon_code);
        }
        map.set(offerStatus.coupon_code, tmpVal);

      }

      var newData = [];
      for (let [key, value] of map.entries()) {

        newData.push(
          {
            name: key,
            value: value
          },
        );
        newLables.push(key);
      }



      this.offerChartOptions = {
        //color: [colors.GREEN, colors.PINK, colors.YELLOW, colors.BLUE],
        color: ["#ffe74cff", "#ff5964ff", "#35a7ffff", colors.GREEN, "#ee6055ff", "#60d394ff", "#aaf683ff", "#ffd97dff", "#ff9b85ff", "#7c6a0aff", "#babd8dff", "#ffdac6ff", "#fa9500ff", "#eb6424ff", "#6bf178ff", "#e0acd5ff", "#3993ddff", "#f4ebe8ff", "#29e7cdff",],
        tooltip: {
          trigger: 'item',
          formatter: function (params) {

            return `Orders with <br />
                    ${params.name}: ${params.data.value} (${params.percent}%)`;
          }
        },

        legend: {
          top: 'center',
          right: 'right',
          data: newLables,
          textStyle: {
            color: '#6E6E6E'
          }
        },
        series: [{
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['24%', '50%'],
          label: {
            show: false
          },

          labelLine: {
            normal: {
              show: false
            }
          },
          hoverAnimation: false,
          avoidLabelOverlap: false,
          data: newData
        }]
      };
    });

  }







  public ngOnInit(): void {
    this.selectedStatsLightBlueData = this.projectsStatsData.lightBlue.daily;
    this.selectedStatsSingAppData = this.projectsStatsData.singApp.daily;
    this.selectedStatsRNSData = this.projectsStatsData.rns.daily;

    this.initChart();
  }

  public initChart(): void {
    this.chartOptions = {
      chart: {
        type: 'bar',
        height: 100,
        width: 130,
        toolbar: {
          show: false
        }
      },
      legend: {
        show: false
      },
      grid: {
        show: false
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '70%',
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug'
        ],
        labels: {
          show: false
        },
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        }
      },
      yaxis: {
        show: false
      },
      tooltip: {
        y: {
          formatter(val) {
            return '$ ' + val + ' thousands';
          }
        }
      }
    };
  }

  public changeDateType(dateType: string, projectType: string): void {
    switch (projectType) {
      case this.projectsType.lightBlue:
        switch (dateType) {
          case 'Weekly':
            this.selectedStatsLightBlueData = this.projectsStatsData.lightBlue.week;
            break;
          case 'Monthly':
            this.selectedStatsLightBlueData = this.projectsStatsData.lightBlue.monthly;
            break;
          default:
            this.selectedStatsLightBlueData = this.projectsStatsData.lightBlue.daily;
        }
        break;
      case this.projectsType.SingApp:
        switch (dateType) {
          case 'Weekly':
            this.selectedStatsSingAppData = this.projectsStatsData.singApp.week;
            break;
          case 'Monthly':
            this.selectedStatsSingAppData = this.projectsStatsData.singApp.monthly;
            break;
          default:
            this.selectedStatsSingAppData = this.projectsStatsData.singApp.daily;
        }
        break;
      case this.projectsType.RNS:
        switch (dateType) {
          case 'Weekly':
            this.selectedStatsRNSData = this.projectsStatsData.rns.week;
            break;
          case 'Monthly':
            this.selectedStatsRNSData = this.projectsStatsData.rns.monthly;
            break;
          default:
            this.selectedStatsRNSData = this.projectsStatsData.rns.daily;
        }
        break;
    }
  }
}
