<h4 mat-dialog-title>Status</h4>
<form [formGroup]="statusProdMapForm" (change)="formChanged()" (ngSubmit)="onSubmit()">

    <div mat-dialog-content>
        <mat-grid-list  cols="1" rowHeight="50px">
            <mat-slide-toggle formControlName="status">Active</mat-slide-toggle>
        </mat-grid-list>
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button type="submit" color="primary" class="Update-btn">Save</button>
        <button mat-raised-button type="button" class="Discard-btn" (click)="onNoClick()">Cancel</button>

        <!-- <button mat-button (click)="onNoClick()">Close</button> -->
</div>
</form>
