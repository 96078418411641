import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map,first } from 'rxjs/operators'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { User } from '../models';
import {AuthReq,AuthRes} from '../models/auth-req-res';
import { routes } from '../../../consts';
import {environment} from '../../../../environments/environment';
import {SuccessToastComponent} from '../components/success-toast/success-toast.component'
import {ErrorToastrComponent} from '../components/error-toastr/error-toastr.component'
//import { SuccessToastComponent } from '../success-toast/success-toast.component';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public routers: typeof routes = routes; 
  public toastrPosition: string = 'toast-top-right';
  public timeOut = 3000;
  public username:string="";

  constructor(private http:HttpClient,private router: Router,private toastrService: ToastrService){
    this.username = localStorage.getItem('username'); 
  }

  public login(aReq:AuthReq): void {  
    const headers=new HttpHeaders();
    headers.set('Content-Type','application/json charset=utf-8'); 
 
    this.http.post<AuthRes>(environment.apiUrl+"/auth/login",{"mobileNo":aReq.mobileNo,"password":aReq.password,  "mode":"admin",
     "app_version":"V-1.0.0"},{headers:headers}).pipe(map((user:AuthRes)=>{
      localStorage.setItem('token', user.accessToken); 
      localStorage.setItem('username', user.mobileNo); 
      this.username = localStorage.getItem('username'); 

      this.toastrService.show(
        null,
        null,
        {
          positionClass: this.toastrPosition,
          toastComponent: SuccessToastComponent,
          timeOut: this.timeOut,
          tapToDismiss: false
        }
      );

      this.router.navigate([this.routers.DASHBOARD]).then(); 

   
    })).subscribe(
      data => console.log(data),
      err => {
        this.toastrService.show(
          null,
          null,
          {
            positionClass: this.toastrPosition,
            toastComponent: ErrorToastrComponent,
            timeOut: this.timeOut,
            tapToDismiss: false
          }
        );
      },
      () => console.log('yay')
    ) 


     

  }

  public sign(): void {
    localStorage.setItem('token', 'token');
  }

  public signOut(): void {
    localStorage.removeItem('token');
  }

  public getUser(): Observable<User> {
    return of({
      name: this.username,
      lastName: ''
    });
  }
}
