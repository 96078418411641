import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Router} from '@angular/router';
import { Injectable } from '@angular/core';
import {routes} from '../../../consts/'

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    
   
  public routers: typeof routes = routes;

   constructor(public router:Router){

   } 
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
            console.log('this is client side error');
            errorMsg = `Error: ${error.error.message}`;
          }
          else { 
            errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
            if(error.status==401){ 
                localStorage.removeItem('token');
                this.router.navigate([this.routers.LOGIN]).then(); 
            }
          }
          console.log(errorMsg);
          return throwError(errorMsg);
        })
      )
  }
}