import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, } from '@angular/material/dialog';
import { Offer, Product } from '../../models';
import { OffersService } from '../../services';
import { SuccessToastDeleteComponent } from '../success-toast-delete/success-toast-delete.component';

import { ProductGroup } from 'src/app/pages/products/models';
import { routes } from 'src/app/consts';
import { Router } from '@angular/router';
import { OfferDetailComponent } from '../offer-detail/offer-detail.component';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialog } from 'src/app/shared/ui-elements/confirmation/confirmation-dialog.component';
import { StatusDialogComponent } from '../offer-detail/status-dialog/status-dialog.component';

import * as moment from 'moment';

@Component({
  selector: 'app-offers-table',
  templateUrl: './offers-table.component.html',
  styleUrls: ['./offers-table.component.scss']
})
export class OffersTableComponent implements OnInit {
  @Input() offerTableData: Offer[];
  public displayedColumns: string[] = ['id', 'title', 'msg', 'coupon_code', 'discount', 'discount_per', 'created', 'expiry', 'status', 'approval_status', 'detail'];
  public dataSource: MatTableDataSource<Offer>;
  public selection = new SelectionModel<Offer>(true, []);
  public routers: typeof routes = routes;
  public isShowFilterInput = false;
  isLoadingResults = true;
  public toastrPosition: string = 'toast-top-right';
  public timeOut = 3000;

  constructor(private service: OffersService, public dialog: MatDialog, private router: Router, private toastrService: ToastrService) {
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public ngOnInit(): void {
    this.dataSource = new MatTableDataSource<Offer>();

    this.service.loadOffersFromHttp().subscribe((res: any) => {
      this.dataSource.data = res;
      this.isLoadingResults = false;
    });

    this.dataSource.paginator = this.paginator;
  }


  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected(): boolean {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  public checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public showFilterInput(): void {
    this.isShowFilterInput = !this.isShowFilterInput;
    this.dataSource = new MatTableDataSource<Offer>(this.offerTableData);
    this.dataSource.paginator = this.paginator;

  }

  public editRow(row?: any): void {
    const dialogRef = this.dialog.open(OfferDetailComponent, {
      width: '440px', disableClose: true,
      data: row || {}
    }).afterClosed().subscribe(item => {

      this.loadOffers();
    })
  }


  public deleteRow(row): void {
    this.service.deleteOfferHttp(row).subscribe((res: any) => {
      //console.log(res);
      this.toastrService.show(
        null,
        null,
        {
          positionClass: this.toastrPosition,
          toastComponent: SuccessToastDeleteComponent,
          timeOut: this.timeOut,
          tapToDismiss: false
        }
      );


      this.loadOffers();
    });
  }

  getExpiry(expiry: Date) {
    let expired = false;
    if (moment(expiry).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
      expired = true;
    }
    return expired;
  }

  confirmDelete(row) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'Cancel'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        console.log('Confirmed');
        this.deleteRow(row);
      }
    });
  }
  today = new Date();
  statusDialog(row): void {
    const dialogRef = this.dialog.open(StatusDialogComponent, {
      width: '200px',
      data: row,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadOffers();
    });
  }

  loadOffers() {
    this.service.loadOffersFromHttp().subscribe((res: any) => {
      this.dataSource.data = res;
      this.isLoadingResults = false;
    });
  }

}
