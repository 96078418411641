<mat-card class="chart">
  <mat-card-title class="chart__header">
    <p class="chart__title">Sales Chart</p>
    <div class="chart-legend">
      <div class="chart-legend__item">
        <div class="chart-legend__icon blue"></div><span class="chart-legend__title">Sales</span>
      </div>

    </div>

    <mat-select class="chart-select" [(ngModel)]="selectedMatSelectValue"
      (selectionChange)="changedMatSelectionValue()">
      <mat-option [value]="matSelectFields.thisWeek">This Week</mat-option>
      <mat-option [value]="matSelectFields.lastWeek">Last Week</mat-option>
      <mat-option [value]="matSelectFields.thisMonth">This Month</mat-option>
      <mat-option [value]="matSelectFields.lastMonth">Last Month</mat-option>
    </mat-select>
  </mat-card-title>

  <mat-card-content class="chart__content">
    <div #chart class="chart__content-item"></div>
  </mat-card-content>
</mat-card>