import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services';
import { routes } from '../../../../consts';
import {AuthReq} from '../../models/auth-req-res';
import { environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss']
})
export class AuthPageComponent {
  public todayDate: Date = new Date();
  public routers: typeof routes = routes;
  public authReq:AuthReq; 
  public buildTag:String="";

  constructor(
    private service: AuthService,
    private router: Router
  ) {

    const token = localStorage.getItem('token');
    this.buildTag=environment.buildTag;

    if (token) {
      
      this.router.navigate([this.routers.DASHBOARD]);
    }

   }

  public sendLoginForm($event): void { 
    
    
    this.service.login($event);

    //this.router.navigate([this.routers.DASHBOARD]).then();
  }

  public sendSignForm(): void {
    this.service.sign();

    this.router.navigate([this.routers.DASHBOARD]).then();
  }
}
