<mat-card class="support-requests">
  <mat-card-title class="support-requests__header">
    <h5 class="support-requests__title">Recent Orders</h5>
    <app-settings-menu></app-settings-menu>
  </mat-card-title>

  <mat-card-content class="support-requests__content">
    <table mat-table [dataSource]="supportRequestData" class="support-requests__table">

      <ng-container *ngFor="let column of displayedColumns" matColumnDef="{{column}}"
        class="support-requests__table-row">
        <th mat-header-cell *matHeaderCellDef class="support-requests__table-row-title">
          <span *ngIf="column !== 'user_mode'">{{column}}</span>
          <span *ngIf="column === 'user_mode'">Ordered By</span>
        </th>


        <td mat-cell *matCellDef="let element" class="support-requests__table-content">
          <span
            *ngIf="column !== 'Status' && column !== 'TotalOrderValue' &&  column !== 'discount_amt'">{{element[column]}}</span>
          <span *ngIf="column === 'TotalOrderValue' || column === 'discount_amt' ">₹
            {{element[column]   | number:'.3'}}</span>
          <div *ngIf="column === 'Status'" class="support-requests__content-badge" [ngClass]="getStatus(element)">
            <span>{{getStatus(element)}}</span>
          </div>





        </td>
      </ng-container>




      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
</mat-card>