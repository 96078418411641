<div class="auth-page">
  <div class="auth-page__logo">
    <div class="auth-page__logo-wrapper">
       
      <h6 class="auth-page__logo-title">BAWA Surgical's Web Admin Console</h6>
    </div>
  </div>

  <div class="auth-page__content-block">
    <div class="auth-page__content-wrapper">

      <h4 class="auth-page__group-title"> </h4>



      <app-login-form (sendLoginForm)="sendLoginForm($event)"></app-login-form>




      <div class='build-tag'>
        {{buildTag}}
        </div>
        
      <p class="auth-page__footer-title">© 2021-{{todayDate | year}} BAWA Surgicals
        All rights reserved.</p>
    </div>
  </div>
  
</div>