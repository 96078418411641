export enum routes {
  DASHBOARD = '/dashboard',
  TYPOGRAPHY = '/typography',
  TABLES = '/tables',
  NOTIFICATION = '/notification',
  UI_ELEMENTS_ICONS = '/ui/icons',
  UI_ELEMENTS_CHARTS = '/ui/charts',
  UI_ELEMENTS_MAP = '/ui/map',
  LOGIN = '/login',

  USERS = '/users',
  PRODUCTS = '/products',
  PRODUCT_GROUP = '/product_group',
  PROMOTIONS = '/promotions',
  OFFERS = '/offers',
  PRODUCT_MAPPING = '/product-mapping',
  COMMISSION = '/commission',
  ORDERS = '/orders',
  BILLS = '/bills',
}
