<mat-toolbar class="header">

  <div class="header__title">
    <button class="header__title-button" mat-mini-fab (click)="openMenu()">
      <mat-icon *ngIf="!isMenuOpened" class="header__title-button-icon">menu</mat-icon>
      <mat-icon *ngIf="isMenuOpened" class="header__title-button-icon">menu</mat-icon>
    </button>
    <h6 class="header__title-text">Admin</h6>
  </div>

  <div class="header-toolbar">
     
    <!--<app-search></app-search>-->
    <app-notifications></app-notifications>
    <app-email [emails]="emails$ | async"></app-email>
    <app-user [user]="user$ | async" (signOut)="signOut()"></app-user>
  </div>

</mat-toolbar>
