<mat-list>

  <mat-list-item routerLink="{{routes.DASHBOARD}}" routerLinkActive="active">
    <mat-icon>home</mat-icon> Dashboard
  </mat-list-item>
  <!--
<mat-list-item routerLink="{{routes.USERS}}" routerLinkActive="active">
  <mat-icon>supervised_user_circle</mat-icon> Users
</mat-list-item> -->

  <mat-list-item routerLink="{{routes.PRODUCT_GROUP}}" routerLinkActive="active">
    <mat-icon>group_work</mat-icon> Groups
  </mat-list-item>

  <mat-list-item routerLink="{{routes.PRODUCTS}}" routerLinkActive="active">
    <mat-icon>list</mat-icon> Products
  </mat-list-item>



  <mat-list-item routerLink="{{routes.PROMOTIONS}}" routerLinkActive="active">
    <mat-icon>local_offer</mat-icon> Promotions
  </mat-list-item>



  <mat-list-item routerLink="{{routes.OFFERS}}" routerLinkActive="active">
    <mat-icon>redeem</mat-icon> Offers
  </mat-list-item>

  <mat-list-item routerLink="{{routes.PRODUCT_MAPPING}}" routerLinkActive="active">
    <mat-icon>widgets</mat-icon> Offers Mapping
  </mat-list-item>

  <mat-list-item routerLink="{{routes.COMMISSION}}" routerLinkActive="active">
    <mat-icon>supervisor_account</mat-icon> Commision
  </mat-list-item>


  <mat-list-item routerLink="{{routes.ORDERS}}" routerLinkActive="active">
    <mat-icon>attach_money</mat-icon> Orders
  </mat-list-item>

  <mat-list-item routerLink="{{routes.BILLS}}" routerLinkActive="active">
    <mat-icon>money</mat-icon> Bills
  </mat-list-item>

</mat-list>

<div class='build-tag'>
  {{buildTag}}
</div>