import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';


import { Inject } from '@angular/core';
import { Product, ProductGroup, Offer } from '../../models';
import { OffersService } from '../../services';
import { ToastrService } from 'ngx-toastr';
import { SuccessToastComponent } from '../success-toast/success-toast.component';
import { SuccessToastDeleteComponent } from '../success-toast-delete/success-toast-delete.component';
import { SuccessToastUpdateComponent } from '../success-toast-update/success-toast-update.component';
@Component({
  selector: 'app-offer-detail',
  templateUrl: './offer-detail.component.html',
  styleUrls: ['./offer-detail.component.scss']
})
export class OfferDetailComponent implements OnInit {

  public breakpoint: number; // Breakpoint observer code
  public editOfferForm: FormGroup;
  wasFormChanged = true;

  selectedFiles?: FileList;
  selectedFileNames: string[] = [];

  progressInfos: any[] = [];
  message: string[] = [];

  public toastrPosition: string = 'toast-top-right';
  public timeOut = 3000;
  previews: string[] = [];
  imageInfos?: Observable<any>;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Offer,
    private service: OffersService,
    private toastrService: ToastrService) { }

  options: ProductGroup[] = [];

  // Choose city using select dropdown
  changeProdGroup(e) {
    //console.log(e.value)


  }

  // Getter method to access formcontrols
  get heading() {
    return this.editOfferForm.get('heading');
  }

  get msg() {
    return this.editOfferForm.get('msg');
  }

  get expiry() {
    return this.editOfferForm.get('expiry');
  }

  get status() {
    return this.editOfferForm.get('status');
  }
  get approval_status() {
    return this.editOfferForm.get('approval_status');
  }


  ngOnInit(): void {
    console.log(this.data);
    this.editOfferForm = this.fb.group({
      heading: [this.data.heading, [Validators.required]],
      msg: [this.data.msg, [Validators.required, Validators.minLength(20), Validators.maxLength(200)]],
      status: [this.data.status],
      approval_status: [this.data.approval_status],
      expiry: [this.data.expiry, [Validators.required]],
      coupon_code: [this.data.coupon_code, [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      discount: [this.data.discount, [Validators.required]],
      discount_per: [this.data.discount_per, [Validators.required]],
      id: [+this.data.id],
    });
    this.breakpoint = window.innerWidth <= 600 ? 1 : 2;
    const disableEnableOptions = { emitEvent: false };

    this.editOfferForm.get('discount').valueChanges.subscribe(val => {
      if (val) {
        this.editOfferForm.get('discount_per').disable(disableEnableOptions);
      } else {
        this.editOfferForm.get('discount_per').enable(disableEnableOptions);
      }
    });
    this.editOfferForm.get('discount_per').valueChanges.subscribe(val => {
      if (val) {
        this.editOfferForm.get('discount').disable(disableEnableOptions);
      } else {
        this.editOfferForm.get('discount').enable(disableEnableOptions);
      }
    });

  }
  public checkError = (controlName: string, errorName: string) => {
    return this.editOfferForm.controls[controlName].hasError(errorName);
  }
  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  cancelDialog(): void {
    this.dialog.closeAll();
  }


  onSubmit(): void {
    console.log(this.editOfferForm.value);
    if (this.wasFormChanged && this.editOfferForm.valid) {
      console.log(this.editOfferForm.value);

      const { status, approval_status, id, discount, discount_per, ...formValue } = this.editOfferForm.value;
      let promoStatus = status ? 1 : 0;
      let approvalStatus = approval_status ? 1 : 0;
      let service = this.service.addOfferHttp({ ...formValue, status: promoStatus, approval_status: approvalStatus, discount: discount || 0, discount_per: discount_per || 0 });

      if (id) {
        service = this.service.updateOfferHttp({ ...this.editOfferForm.value, approval_status: approvalStatus, status: promoStatus, discount: discount || 0, discount_per: discount_per || 0 })
        service.subscribe((res: any) => {

         if(res && res.code){
          this.toastrService.error('Coupon code is already exist!', 'News Alert!');

         } else {
            this.toastrService.show(
              null,
              null,
              {
                positionClass: this.toastrPosition,
                toastComponent: SuccessToastUpdateComponent,
                timeOut: this.timeOut,
                tapToDismiss: false
              }
            );

          }

          this.dialog.closeAll();
        });
      } else {

        service.subscribe((res: any) => {
          if(res && res.code){
            this.toastrService.error('Coupon code is already exist!', 'News Alert!');
  
          } else {
            this.toastrService.show(
              null,
              null,
              {
                positionClass: this.toastrPosition,
                toastComponent: SuccessToastComponent,
                timeOut: this.timeOut,
                tapToDismiss: false
              }
            );
          }

          this.dialog.closeAll();
        });
      }

    }


  }


  deleteAction(): void {


    this.service.deleteOfferHttp(this.editOfferForm.value).subscribe((res: any) => {

      //console.log(res);

      //console.log(res);
      this.toastrService.show(
        null,
        null,
        {
          positionClass: this.toastrPosition,
          toastComponent: SuccessToastDeleteComponent,
          timeOut: this.timeOut,
          tapToDismiss: false
        }
      );

      this.dialog.closeAll();



    });
  }





  formChanged() {
    this.wasFormChanged = true;
  }


  selectFiles(event: any): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFileNames = [];
    this.selectedFiles = event.target.files;

    this.previews = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          console.log(e.target.result);
          this.editOfferForm.controls['product_img_content'].setValue(e.target.result);
          this.previews.push(e.target.result);
        };

        reader.readAsDataURL(this.selectedFiles[i]);

        this.selectedFileNames.push(this.selectedFiles[i].name);
      }
    }
  }

  upload(idx: number, file: File): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };

    if (file) {
      /*this.uploadService.upload(file).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round(
              (100 * event.loaded) / event.total
            );
          } else if (event instanceof HttpResponse) {
            const msg = 'Uploaded the file successfully: ' + file.name;
            this.message.push(msg);
            this.imageInfos = this.uploadService.getFiles();
          }
        },
        (err: any) => {
          this.progressInfos[idx].value = 0;
          const msg = 'Could not upload the file: ' + file.name;
          this.message.push(msg);
        }
      );*/
    }
  }

  uploadFiles(): void {
    this.message = [];

    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i]);
      }
    }
  }


}
