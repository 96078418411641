<app-layout>

  <app-sales-stat-chart [projectsStatsData]="projectsStatsData$ | async"></app-sales-stat-chart>

  <app-sales-line-chart>
  </app-sales-line-chart>


  <app-recent-orders [supportRequestData]="supportRequestData$ | async"></app-recent-orders>

  <app-footer></app-footer>
</app-layout>