<div>
  <div class="add-title">
    <h3 class="primary">Add Offer</h3>
  </div>
  <div>
    <form [formGroup]="editOfferForm" (change)="formChanged()" (ngSubmit)="onSubmit()">

      <mat-grid-list cols="2" [cols]="breakpoint" rowHeight="100px" (window:resize)="onResize($event)">
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field>
              <input matInput placeholder="Title" required formControlName="heading">
              <mat-error *ngIf="checkError('heading', 'required')">Offer Title is required</mat-error>

            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field>
              <input matInput placeholder="Message" required formControlName="msg">
              <mat-error *ngIf="checkError('msg', 'required')">Offer Message is required</mat-error>
              <mat-error *ngIf="checkError('msg', 'minlength')">Message characters should be more than 20
                characters</mat-error>
              <mat-error *ngIf="checkError('msg', 'maxlength')">Message characters should be less than 200
                characters</mat-error>
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field>
              <input matInput placeholder="Coupon Code" required formControlName="coupon_code">
              <mat-error *ngIf="checkError('coupon_code', 'required')">Offer coupon code is required</mat-error>
              <mat-error *ngIf="checkError('coupon_code', 'minlength')">Offer coupon code should be more than 4
                characters</mat-error>
              <mat-error *ngIf="checkError('coupon_code', 'maxlength')">Offer coupon code should be less than 20
                characters</mat-error>
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field>
              <input matInput type="number" placeholder="Discount Amount" required formControlName="discount">
              <mat-error *ngIf="checkError('discount', 'required')">Offer Discount Amount is required</mat-error>

            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field>
              <input matInput type="number" placeholder="Discount Percent" required formControlName="discount_per">
              <mat-error *ngIf="checkError('discount_per', 'required')">Offer Discount (%) is required</mat-error>

            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field>
              <!-- <mat-label></mat-label> -->
              <input matInput [matDatepicker]="picker" placeholder="Expiry date" required formControlName="expiry">
              <mat-error *ngIf="checkError('expiry', 'required')">Expiry date is required</mat-error>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </mat-grid-tile>

        <mat-grid-tile>
          <div class="text-inside">
            <mat-slide-toggle formControlName="approval_status">Approval</mat-slide-toggle>
          </div>
        </mat-grid-tile>
        
      </mat-grid-list>


      <div class="btn-sec">
        <button mat-raised-button type="submit" color="primary" class="Update-btn">Save</button>
        <button mat-raised-button type="button" class="Discard-btn" (click)="cancelDialog()">Cancel</button>
      </div>

    </form>
  </div>
</div>