import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { OffersService } from '../../../services';
import { Offer } from '../../../models';

export interface DialogData {
  ProductName: string;
  SalePrice: string;
  ProductCode: number;
}

@Component({
  selector: 'app-status-dialog',
  templateUrl: './status-dialog.component.html',
  styleUrls: ['./status-dialog.component.css']
})
export class StatusDialogComponent implements OnInit {

  public statusProdMapForm: FormGroup;
  public editProdMapsForm: FormGroup;
  wasFormChanged = true;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private service: OffersService,
    public dialogRef: MatDialogRef<StatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  options: Offer[] = [];

  ngOnInit(): void {
    console.log(this.data);
    this.statusProdMapForm = this.fb.group({
      status: [this.data?.status],
      id: [+this.data?.id],
    });
  }
  get status() {
    return this.statusProdMapForm.get('status');
  }

  onSubmit(): void {
    debugger
    console.log(this.statusProdMapForm.value);
    if (this.wasFormChanged && this.statusProdMapForm.valid) {
      console.log(this.statusProdMapForm.value);

      const { status, id } = this.statusProdMapForm.value;
      let promoStatus = status ? 1 : 0;
      if (id) {
        this.service.updateOfferHttp({ ...this.data, status: promoStatus}).subscribe((res: any) => {
          this.dialogRef.close();
        });
  
      }
    
    }

  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  formChanged() {
    this.wasFormChanged = true;
  }

}