<div class="project-stat">


  <mat-card class="project-stat__item">
    <mat-card-title class="project-stat__title">
      <h5 class="project-stat__title-text">Customer</h5>

      <p class="project-stat-content__stat-item-title">in {{recentActiveDays}} Days </p>


    </mat-card-title>
    <mat-card-content class="project-stat-content">
      <div class="project-stat-content__total-info-wrapper">
        <div class="project-stat-content__total-info">
          <p class="project-stat-content__total-info-users">{{totalActiveCustomer}}</p>
          <p class="project-stat-content__total-info-percent"> Active</p>
        </div>
        <div class="project-stat-content__total-info-chart" *ngIf="this.customerChartOptions">
          <apx-chart [series]="customerChartOptions.series" [chart]="customerChartOptions.chart"
            [dataLabels]="customerChartOptions.dataLabels" [plotOptions]="customerChartOptions.plotOptions"
            [yaxis]="customerChartOptions.yaxis" [legend]="customerChartOptions.legend"
            [fill]="customerChartOptions.fill" [stroke]="customerChartOptions.stroke"
            [tooltip]="customerChartOptions.tooltip" [xaxis]="customerChartOptions.xaxis"
            [grid]="customerChartOptions.grid" [colors]="colors.BLUE"></apx-chart>
        </div>
      </div>

      <div class="project-stat-content__stat-wrapper">
        <div class="project-stat-content__stat-item">
          <div class="project-stat-content__stat-value-wrapper">
            <h6 class="project-stat-content__stat-value">{{totalCustomer}}</h6>
          </div>
          <p class="project-stat-content__stat-item-title">Total</p>
        </div>

      </div>
    </mat-card-content>
  </mat-card>




  <mat-card class="project-stat__item">
    <mat-card-title class="project-stat__title">
      <h5 class="project-stat__title-text">Salesman</h5>

      <p class="project-stat-content__stat-item-title">in {{recentActiveDays}} Days </p>


    </mat-card-title>
    <mat-card-content class="project-stat-content">
      <div class="project-stat-content__total-info-wrapper">
        <div class="project-stat-content__total-info">
          <p class="project-stat-content__total-info-users">{{totalActiveSalesman}}</p>
          <p class="project-stat-content__total-info-percent"> Active</p>
        </div>
        <div class="project-stat-content__total-info-chart" *ngIf="this.salesmanChartOptions">
          <apx-chart [series]="salesmanChartOptions.series" [chart]="salesmanChartOptions.chart"
            [dataLabels]="salesmanChartOptions.dataLabels" [plotOptions]="salesmanChartOptions.plotOptions"
            [yaxis]="salesmanChartOptions.yaxis" [legend]="salesmanChartOptions.legend"
            [fill]="salesmanChartOptions.fill" [stroke]="salesmanChartOptions.stroke"
            [tooltip]="salesmanChartOptions.tooltip" [xaxis]="salesmanChartOptions.xaxis"
            [grid]="salesmanChartOptions.grid" [colors]="colors.PINK"></apx-chart>
        </div>
      </div>

      <div class="project-stat-content__stat-wrapper">
        <div class="project-stat-content__stat-item">
          <div class="project-stat-content__stat-value-wrapper">
            <h6 class="project-stat-content__stat-value">{{totalSalesman}}</h6>
          </div>
          <p class="project-stat-content__stat-item-title">Total</p>
        </div>

      </div>
    </mat-card-content>
  </mat-card>






  <mat-card class="project-stat__item">
    <mat-card-title class="revenue-chart__header">
      <h5 class="revenue-chart__header-title">Order Status</h5>

      <p class="project-stat-content__stat-item-title">in {{recentActiveDays}} Days </p>
    </mat-card-title>

    <mat-card-content class="revenue-chart__content" *ngIf="this.orderChartOptions">
      <div class="revenue-chart__content-chart" echarts [options]="orderChartOptions"></div>
    </mat-card-content>
  </mat-card>




  <mat-card class="project-stat__item">
    <mat-card-title class="revenue-chart__header">
      <h5 class="revenue-chart__header-title">Offers</h5>

      <p class="project-stat-content__stat-item-title">in {{recentActiveDays}} Days </p>
    </mat-card-title>

    <mat-card-content class="revenue-chart__content" *ngIf="this.offerChartOptions">
      <div class="revenue-chart__content-chart" echarts [options]="offerChartOptions"></div>
    </mat-card-content>
  </mat-card>


</div>