import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';

import { MatDatepickerModule } from
  '@angular/material/datepicker';
import { MatNativeDateModule } from
  '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


import { OffersRoutingModule } from './offers-group-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { OffersService } from './services';

import { SuccessToastComponent } from './components/success-toast/success-toast.component';

import { SuccessToastDeleteComponent } from './components/success-toast-delete/success-toast-delete.component';
import { OfferPageComponent } from './containers';
import { OffersTableComponent } from './components/offers-table/offers-table.component';
import { OfferDetailComponent } from './components/offer-detail/offer-detail.component';
import { SuccessToastUpdateComponent } from './components/success-toast-update/success-toast-update.component';
import { StatusDialogComponent } from './components/offer-detail/status-dialog/status-dialog.component';

@NgModule({
  declarations: [OfferPageComponent, OffersTableComponent, OfferDetailComponent, SuccessToastComponent, SuccessToastDeleteComponent, SuccessToastUpdateComponent, StatusDialogComponent],

  imports: [
    CommonModule,
    OffersRoutingModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    SharedModule,
    MatDialogModule,
    MatGridListModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatListModule
  ],
  providers: [
    OffersService
  ]
})
export class OffersModule { }
