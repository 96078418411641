export class AuthReq {
    mobileNo: string;
    password: string;
    mode: string;
    app_version: string;
    
    constructor(public _mobileNo:string,public _password:string,) {
        this.mobileNo=_mobileNo;
        this.password=_password;
        this.mode="salesrep";
        this.app_version="V-1.0.0";
    }

  }

  export class AuthRes {
    mobileNo: string;
    accessToken: string;
    expiresIn: number; 
    
    constructor(public _mobileNo:string,public _accessToken:string,public _expiresIn:number) {
        this.mobileNo=_mobileNo;
        this.accessToken=_accessToken;
        this.expiresIn=_expiresIn; 
    }

  }