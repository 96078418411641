import { Component, Input } from '@angular/core';

import { SupportRequestData } from '../../models/support-request-data';


import { DashboardService } from '../../services';

@Component({
  selector: 'app-recent-orders',
  templateUrl: './recent-orders.component.html',
  styleUrls: ['./recent-orders.component.scss']
})
export class RecentOrdersComponent {


  constructor(private service: DashboardService) {
    this.service.loadRecentOrderTableDataFromHttp().subscribe((res: any) => {
      this.supportRequestData = res;
    });
  }



  @Input() supportRequestData: SupportRequestData[];
  public displayedColumns: string[] = ['OrderNumber', 'user_mode', 'ReferenceNo', 'CustomerName', 'coupon_code', 'discount_amt', 'Status', 'OrderTime', 'TotalItems', 'TotalQty', 'TotalOrderValue',];



  getStatus(element) {

    if (element['Status'] == 0) {
      return "in-progress";
    } else {
      if (element['TransactionValidity'])
        return "cancelled";
      else
        return "processed";
    }
  }
}
