<mat-card class="employee-table-wrapper">
  <mat-card-title class="employee-table-wrapper__header">
    <p *ngIf="!isShowFilterInput" class="employee-table-wrapper__title">Offer List</p>
    <div *ngIf="isShowFilterInput" class="employee-table-wrapper__search">
      <div class="employee-table-wrapper__icon-wrapper">
        <mat-icon class="employee-table-wrapper__icon">search</mat-icon>
      </div>
      <input matInput class="employee-table-wrapper__search-input" (keyup)="applyFilter($event)">
      <button class="employee-table-wrapper__button" mat-mini-fab (click)="showFilterInput()">
        <mat-icon class="employee-table-wrapper__icon">close</mat-icon>
      </button>
    </div>
    <div>
      <button class="employee-table-wrapper__button" mat-mini-fab (click)="showFilterInput()">
        <mat-icon class="employee-table-wrapper__icon">search</mat-icon>
      </button>
      <button mat-fab color="primary" (click)="editRow()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </mat-card-title>
  <mat-card-content class="employee-table__content">


    <table class="employee-table__table" mat-table [dataSource]="dataSource">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th class="employee-table__table-checkbox" mat-header-cell *matHeaderCellDef>
          <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td class="employee-table__table-checkbox" mat-cell *matCellDef="let row">
          <mat-checkbox color="primary" (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>


      <ng-container matColumnDef="id">
        <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef> ID</th>
        <td class="employee-table__table-body" mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef> Title </th>
        <td class="employee-table__table-body" mat-cell *matCellDef="let element"> {{element.heading}} </td>
      </ng-container>


      <ng-container matColumnDef="msg">
        <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef> Msg </th>
        <td class="employee-table__table-body" mat-cell *matCellDef="let element"> {{element.msg}} </td>
      </ng-container>


      <ng-container matColumnDef="coupon_code">
        <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef> Coupon Code </th>
        <td class="employee-table__table-body" mat-cell *matCellDef="let element"> {{element.coupon_code}} </td>
      </ng-container>

      <ng-container matColumnDef="discount">
        <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef> Discount</th>
        <td class="employee-table__table-body" mat-cell *matCellDef="let element"> {{ '₹ ' + element.discount}} </td>
      </ng-container>

      <ng-container matColumnDef="discount_per">
        <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef> Discount</th>
        <td class="employee-table__table-body" mat-cell *matCellDef="let element"> {{element.discount_per + ' %'}} </td>
      </ng-container>


      <ng-container matColumnDef="created">
        <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef> Created </th>
        <td class="employee-table__table-body" mat-cell *matCellDef="let element"> {{element.created | date}} </td>
      </ng-container>


      <ng-container matColumnDef="expiry">
        <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef> Expiry </th>
        <td class="employee-table__table-body" mat-cell *matCellDef="let element" [ngClass]="getExpiry(element.expiry)?'expired':'un_expired'"> {{element.expiry | date}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef> Status </th>
        <td class="employee-table__table-body" mat-cell *matCellDef="let element">
          <button mat-button color="primary" (click)="statusDialog(element)">
            {{element.status ? 'Active' : 'Inactive'}}            
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="approval_status">
        <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef> Approval </th>        
          <td class="employee-table__table-body" mat-cell *matCellDef="let element">
            <div class="commission-requests__content-badge" [ngClass]="element.approval_status==1?'approval':'un_approval'">
              <span>
                {{element.approval_status==1?'Approved':'Un-Approved'}}
              </span>
            </div>
          </td>
      </ng-container>


      <ng-container matColumnDef="detail">
        <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef> Details </th>
        <td class="employee-table__table-body" mat-cell *matCellDef="let element">
          <button mat-icon-button color="primary" (click)="editRow(element)" [disabled]="element.approval_status==1? true:false">
            <mat-icon class="mat-1">file_open</mat-icon>
          </button><button mat-icon-button color="warn" (click)="confirmDelete(element)" [disabled]="element.approval_status==1? true:false">
            <mat-icon class="mat-1">delete</mat-icon>
          </button>
        </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>

    <div>
      <div class="example-loading-shade" *ngIf="isLoadingResults">
        <div class="spinner">
          <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
        </div>


      </div>
    </div>

  </mat-card-content>
  <div class="pagination">
    <mat-paginator [pageSizeOptions]="[10, 15, 100]" showFirstLastButtons></mat-paginator>
  </div>
</mat-card>