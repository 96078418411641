<button class="notification-button" mat-mini-fab >
  <mat-icon matBadge="0" matBadgeColor="warn" class="notification-button__icon">notifications_none</mat-icon>
</button>

<!--<button class="notification-button" mat-mini-fab [matMenuTriggerFor]="bell">
  <mat-icon matBadge="0" matBadgeColor="warn" class="notification-button__icon">notifications_none</mat-icon>
</button>
<mat-menu #bell="matMenu" xPosition="before" class="notification-menu">
  <button mat-menu-item class="notification-menu__button">
    <mat-icon class="notification-menu__icon_yellow">local_offer</mat-icon>Check out this awesome ticket
  </button>
  <button mat-menu-item class="notification-menu__button">
    <mat-icon class="notification-menu__icon_green">thumb_up</mat-icon>What is the best way to get ...
  </button>
  <button mat-menu-item class="notification-menu__button">
    <mat-icon class="notification-menu__icon_pink">notifications_none</mat-icon>This is just a simple notification
  </button>
  <button mat-menu-item class="notification-menu__button">
    <mat-icon class="notification-menu__icon_blue">local_grocery_store</mat-icon>12 new orders has arrived today
  </button>
</mat-menu> -->
