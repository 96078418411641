import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexMarkers,
  ApexXAxis,
  ApexPlotOptions
} from 'ng-apexcharts';

import { DailyLineChartData, SalesChartData, TimeData } from '../../models';
import { colors } from '../../../../consts';
import { customTooltip } from '../../consts';
import * as ApexCharts from 'apexcharts';
import { DashboardService } from '../../services';
import { Observable } from 'rxjs';
import { DecimalPipe } from '@angular/common';

type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  labels: string[];
  stroke: ApexStroke;
  markers: ApexMarkers;
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  tooltip: ApexTooltip;
  legend: ApexLegend;
  colors: string[];
  grid: ApexGrid;
};

enum matSelectedFields {
  thisWeek = 'This Week',
  lastWeek = 'Last Week',
  thisMonth = 'This Month',
  lastMonth = 'Last Month'
}

@Component({
  selector: 'app-sales-line-chart',
  templateUrl: './sales-line-chart.component.html',
  styleUrls: ['./sales-line-chart.component.scss']
})
export class SalesLineChartComponent implements OnInit, AfterViewInit {

  public salesChartData$: Observable<SalesChartData[]>;
  @ViewChild('chart') chart: ElementRef;
  public chartObj: ApexCharts;
  public chartOptions: Partial<ChartOptions>;
  public matSelectFields: typeof matSelectedFields = matSelectedFields;
  public selectedMatSelectValue = matSelectedFields.thisMonth;
  public colors: typeof colors = colors;


  constructor(private service: DashboardService,) {
    this.loadData("this-month", true);
  }


  public loadData(period: string, isFirst: boolean) {
    this.service.loadSalesChartData(period).subscribe((data: SalesChartData[]) => {

      this.initChart(data, isFirst);

    });
  }

  public ngOnInit(): void {
    //console.log(this.salesChartData);
    //this.initChart(this.salesChartData,this.dailyLineChartData.monthlyData, this.dailyLineChartData.labels);


  }
  public ngAfterViewInit() {

  }


  public initChart(dataNew: SalesChartData[], isFirst: boolean): void {

    var newLables: string[] = [];
    var newValues: number[] = [];

    for (const saleDate of dataNew) {
      newLables.push(saleDate.order_date);

      newValues.push(saleDate.value);

    }

    this.chartOptions = {
      legend: {
        show: false
      },
      markers: {
        size: [5]
      },
      series: [
        {
          name: 'Sales',
          type: 'line',
          data: newValues
        }
      ],
      colors: [colors.BLUE],
      chart: {
        toolbar: {
          show: false
        },
        height: 350,
        width: '100%',
        type: 'line',
        stacked: true
      },
      stroke: {
        width: [2, 0, 2],
        curve: ['straight']
      },
      plotOptions: {
        bar: {
          columnWidth: '50%'
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          }
        },
      },
      fill: {
        opacity: 1,
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: 'vertical',
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100]
        }
      },
      labels: newLables,
      xaxis: {
        type: 'datetime',
        labels: {
          style: {
            colors: '#4A4A4A',
            fontSize: '0.875rem',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
          },
        },
      },
      yaxis: {
        show: true,
        labels: {
          style: {
            colors: '#4A4A4A',
            fontSize: '0.875rem',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
          },
          formatter: function (value) {
            return "₹ " + value;
          }

        },
      },


    };

    if (isFirst) {
      this.chartObj = new ApexCharts(
        this.chart.nativeElement,
        this.chartOptions
      )

      this.chartObj.render();
    } else {


      this.chartObj.updateOptions(this.chartOptions);
    }

  };


  public changedMatSelectionValue() {
    switch (this.selectedMatSelectValue) {
      case matSelectedFields.thisWeek:
        this.loadData("this-week", false);
        break;
      case matSelectedFields.lastWeek:
        this.loadData("last-week", false);
        break;
      case matSelectedFields.thisMonth:
        this.loadData("this-month", false);
        break;
      case matSelectedFields.lastMonth:
        this.loadData("last-month", false);
        break;
      default:
        this.loadData("this-week", false);


    }


  }
}
